<template>
    <div class="layout-device-add">
        <div class="layout-device-bread">
            <div class="list-back"><a href="javascript:" @click="onBack"><Icon type="ios-log-out" size="20"/>退出</a></div>
        </div>
        <div class="layout-device-li">
            <div class="box">
                <ul>
                    <li class="item">
                        <span class="label required">目录：</span>
                        <Cascader style="width: 300px"  :data="classifyMenuArray" v-model="newlyParams.deviceType"></Cascader>
                    </li>
                    <li class="item">
                        <span class="label required">设备名称：</span>
                        <Input v-model="newlyParams.deviceName" placeholder="请输入" clearable style="width: 300px" />
                    </li>
                    <li class="item">
                        <span class="label required">设备型号：</span>
                        <Select v-model="newlyParams.deviceModel" clearable style="width:300px">
                            <Option v-for="(deviceModel,key) in deviceModelArray" :value="deviceModel.id" :key="key">{{ deviceModel.name }}</Option>
                        </Select>
                        <a href="javascript:" class="add" @click="deviceModelVisible = true"><Icon custom="icon-manage" style="margin-right: 4px"/>管理设备型号</a>
                    </li>
                    <li class="item">
                        <span class="label required">生产厂家：</span>
                        <Select v-model="newlyParams.manufacturer" clearable style="width:300px">
                            <Option v-for="(manufacturer,key) in manufacturerArray" :value="manufacturer.id" :key="key">{{ manufacturer.name }}</Option>
                        </Select>
                        <a href="javascript:" class="add" @click="manufacturerVisible = true"><Icon custom="icon-manage" style="margin-right: 4px"/>管理生产厂家</a>
                    </li>
                    <li class="item">
                        <span class="label">设备图片：</span>
                        <div class="device-upload">
                            <a href="javascript:" class="btn" v-if="!speedSingleVisible">
                                <Icon type="ios-add" class="icon"/>
                                上传图片
                            </a>
                            <div class="progress" v-if="speedSingleVisible">
                                <Progress :percent="complete" />
                            </div>
                            <input type="file" ref="pathClear" v-if="!speedSingleVisible" class="file" accept="image/png, image/jpeg, image/gif, image/jpg" @change="onFileChange($event)">
                            <div class="file-image" v-if="imageSrc!==''">
                                <img :src="imageSrc" class="image">
                                <div class="handle">
                                    <div class="box">
                                        <a href="javascript:" @click="onImageAmplify"><Icon custom="look-big" :size="25"/></a>
                                        <a href="javascript:" @click="onClearImage"><Icon type="ios-trash-outline" :size="25"/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="item layout-device-btn">
                        <span class="label"></span>
                        <Button type="primary" :disabled="speedSingleVisible" :loading="submitLoading" @click="onAddRecordCheck">保存并继续填写资料</Button>
                    </li>
                </ul>
            </div>
        </div>

        <!--s: Modal 管理生产设备型号-->
        <Modal
            v-model="deviceModelVisible"
            title="管理设备型号"
            width="580"
            :loading="submitLoading"
            @on-visible-change="onModelVisibleChange"
            footer-hide>
            <div class="device-modal-info-box">

                <div class="device-modal-title">
                    <div class="modal-search">
                        <Icon type="ios-search" class="search" size="20"></Icon>
                        <Input v-model="deviceModelValue" placeholder="输入设备型号" style="width: 300px"></Input>
                    </div>
                    <div class="modal-btn">
                        <Button @click="onDeviceModalResetting">重置</Button>
                        <Button type="primary" @click="onDeviceModalSearch">搜索</Button>
                    </div>
                </div>

                <div class="device-modal-list" id="deviceList">
                    <ul>
                        <li class="modal-hover" :key="key" v-for="(deviceModel,key) in deviceModelSelect">
                            <div class="modal-text" v-if="deviceModel.visible">
                                <div class="text">{{ deviceModel.name }}</div>
                                <div class="btn">
                                    <a href="javascript:" @click="onDeviceModalEdit(key)"><Icon type="md-create" /></a>
                                    <a href="javascript:" @click="onDeviceModalDel(key)"><Icon type="ios-close-circle-outline" /></a>
                                </div>
                            </div>
                            <div class="modal-add-li" v-if="!deviceModel.visible">
                                <div class="modal-add">
                                    <Input v-model="deviceModelInfo" placeholder="输入设备型号" show-word-limit maxlength="25" style="width: 402px"></Input>
                                </div>
                                <div class="btn">
                                    <a href="javascript:" @click="onDeviceModelEditCheck(key)"><Icon type="ios-checkmark-circle-outline" /></a>
                                    <a href="javascript:" @click="onDeviceModalClose(key)"><Icon type="ios-close-circle-outline" /></a>
                                </div>
                            </div>
                        </li>
                        <li class="modal-add-li" v-if="deviceAddVisible">
                            <div class="modal-add">
                                <Input v-model="deviceParams.deviceName" placeholder="输入设备型号" show-word-limit maxlength="25" style="width: 402px"></Input>
                            </div>
                            <div class="btn">
                                <a href="javascript:" @click="onDeviceModelCheck"><Icon type="ios-checkmark-circle-outline" /></a>
                                <a href="javascript:" @click="deviceAddVisible = false;deviceParams.deviceName = ''"><Icon type="ios-close-circle-outline" /></a>
                            </div>
                        </li>
                    </ul>
                    <div class="no-data" v-if="deviceModelSelect.length === 0">未找到您要搜索的数据</div>
                </div>

                <div class="device-modal-btn">
                    <Button type="primary" icon="ios-add" :disabled="deviceBtnVisible" ghost @click="onDeviceAddModel">新增</Button>
                </div>

            </div>
          <!--            <div class="device-modal">-->
          <!--                <span class="label">设备型号：</span>-->
          <!--                <Input v-model="deviceParams.deviceName" type="textarea" maxlength="25" show-word-limit placeholder="请输入" style="width: 300px" />-->
          <!--            </div>-->
          <!--            <div class="device-footer">-->
          <!--                <Button @click="deviceModelVisible = false">取 消</Button>-->
          <!--                <Button type="primary" :loading="submitLoading" @click="onDeviceModelCheck">确 定</Button>-->
          <!--            </div>-->
        </Modal>
        <!--e: Modal 管理生产设备型号-->

        <!--s: Modal 新增生产厂家-->
        <Modal
            v-model="manufacturerVisible"
            title="管理生产厂家"
            width="580"
            :loading="submitLoading"
            @on-visible-change="onFactureVisibleChange"
            footer-hide>
            <!--            <div class="device-modal">-->
            <!--                <span class="label">生产厂家：</span>-->
            <!--                <Input v-model="manufacturerParams.manufacturerName" type="textarea" maxlength="25" show-word-limit placeholder="请输入" style="width: 300px" />-->
            <!--            </div>-->
            <!--            <div class="device-footer">-->
            <!--                <Button @click="manufacturerVisible = false;manufacturerParams.manufacturerName = ''">取 消</Button>-->
            <!--                <Button type="primary" :loading="submitLoading" @click="onManufacturerCheck">确 定</Button>-->
            <!--            </div>-->
            <div class="device-modal-info-box">

                <div class="device-modal-title">
                    <div class="modal-search">
                        <Icon type="ios-search" class="search" size="20"></Icon>
                        <Input v-model="deviceFactureValue" placeholder="输入生产厂家" style="width: 300px"></Input>
                    </div>
                    <div class="modal-btn">
                        <Button @click="onDeviceFactureResetting">重置</Button>
                        <Button type="primary" @click="onDeviceFactureSearch">搜索</Button>
                    </div>
                </div>

                <div class="device-modal-list" id="deviceFactureList">
                    <ul>
                        <li class="modal-hover" :key="key" v-for="(deviceFacture,key) in deviceFactureSelect">
                            <div class="modal-text" v-if="deviceFacture.visible">
                                <div class="text">{{ deviceFacture.name }}</div>
                                <div class="btn">
                                    <a href="javascript:" @click="onDeviceFactureEdit(key)"><Icon type="md-create" /></a>
                                    <a href="javascript:" @click="onDeviceFactureDel(key)"><Icon type="ios-close-circle-outline" /></a>
                                </div>
                            </div>
                            <div class="modal-add-li" v-if="!deviceFacture.visible">
                                <div class="modal-add">
                                    <Input v-model="deviceFactureInfo" placeholder="输入生产厂家" show-word-limit maxlength="25" style="width: 402px"></Input>
                                </div>
                                <div class="btn">
                                    <a href="javascript:" @click="onDeviceFactureEditCheck(key)"><Icon type="ios-checkmark-circle-outline" /></a>
                                    <a href="javascript:" @click="onDeviceFactureClose(key)"><Icon type="ios-close-circle-outline" /></a>
                                </div>
                            </div>
                        </li>
                        <li class="modal-add-li" v-if="deviceAddVisible">
                            <div class="modal-add">
                                <Input v-model="manufacturerParams.manufacturerName" placeholder="输入生产厂家" show-word-limit maxlength="25" style="width: 402px"></Input>
                            </div>
                            <div class="btn">
                                <a href="javascript:" @click="onDeviceFactureCheck"><Icon type="ios-checkmark-circle-outline" /></a>
                                <a href="javascript:" @click="deviceAddVisible = false;manufacturerParams.manufacturerName = ''"><Icon type="ios-close-circle-outline" /></a>
                            </div>
                        </li>
                    </ul>
                    <div class="no-data" v-if="deviceFactureSelect.length === 0">未找到您要搜索的数据</div>
                </div>

                <div class="device-modal-btn">
                    <Button type="primary" icon="ios-add" :disabled="deviceBtnVisible" ghost @click="onDeviceAddFacture">新增</Button>
                </div>

            </div>

        </Modal>
        <!--e: Modal 新增生产厂家-->

        <!--s: Modal 查看大图-->
        <ViewImage v-model="viewImageVisible" :imageName="imageName" :imageUrl="imageSrc" />
        <!--e: Modal 查看大图-->
    </div>
</template>
<script>
import NewlyAdded from './newlyAdded'
export default NewlyAdded
</script>
<style lang="less">
@import "newlyAdded";
</style>
